var render = function () {
  var _vm$studentSelected;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Modal', {
    staticClass: "overflow-y-hidden",
    attrs: {
      "modalVisible": _vm.visibleModal,
      "width": "50%",
      "id": "restartProgress"
    },
    on: {
      "close": function close($event) {
        return _vm.hideModal();
      }
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('h1', {
    staticClass: "text-2xl font-bold mb-3 mt-5"
  }, [_vm._v("Are you sure to restart student progress?")]), _c('p', [_vm._v("This action can not be undone")]), _c('div', {
    staticClass: "flex justify-center items-center my-5"
  }, [_c('Button', {
    staticClass: "mr-5",
    attrs: {
      "buttonText": "Restart",
      "type": "secondary"
    },
    on: {
      "action": function action($event) {
        return _vm.restartProgress();
      }
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "Cancel",
      "type": "tertiary"
    },
    on: {
      "action": function action($event) {
        return _vm.hideModal();
      }
    }
  })], 1)])], 2), _c('Modal', {
    staticClass: "overflow-y-hidden",
    attrs: {
      "modalVisible": _vm.visibleModalChangeEmail,
      "id": "changeEmail"
    },
    on: {
      "close": function close($event) {
        return _vm.hideModalChangeEmail();
      }
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('h1', {
    staticClass: "text-2xl font-bold mb-3 mt-5 text-left"
  }, [_vm._v("Change Email")]), _c('div', {
    staticClass: "text-base font-semibold text-left mb-2"
  }, [_vm._v("Current Email: " + _vm._s(_vm.oldEmail))]), _c('br'), _c('TextField', {
    staticClass: "mb-3",
    attrs: {
      "type": "text",
      "label": "New Email",
      "placeholder": "placeholder",
      "borderEnabled": ""
    },
    model: {
      value: _vm.newEmail,
      callback: function callback($$v) {
        _vm.newEmail = $$v;
      },
      expression: "newEmail"
    }
  }), _c('div', {
    staticClass: "flex  gap-4 justify-end my-5"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Cancel",
      "type": "tertiary"
    },
    on: {
      "action": function action($event) {
        return _vm.hideModalChangeEmail();
      }
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "Save"
    },
    on: {
      "action": function action($event) {
        return _vm.showModalConfirmChangeEmail();
      }
    }
  })], 1)], 1)], 2), _c('Modal', {
    staticClass: "overflow-y-hidden",
    attrs: {
      "modalVisible": _vm.visibleModalConfirmChangeEmail,
      "id": "confirmChangeEmail"
    },
    on: {
      "close": function close($event) {
        return _vm.hideModalConfirmChangeEmail();
      }
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('h1', {
    staticClass: "text-2xl font-bold mb-3 mt-5 text-left"
  }, [_vm._v("Change Email")]), _c('div', {
    staticClass: "text-left"
  }, [_c('p', [_vm._v("Previous email: " + _vm._s(_vm.oldEmail))]), _c('p', [_vm._v("New email: " + _vm._s(_vm.newEmail))]), _c('br'), _c('p', [_vm._v(" Note: All activity from previous email will be transferred to new email. This action can not be undone")])]), _c('div', {
    staticClass: "flex  gap-4 justify-end my-5"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Cancel",
      "type": "tertiary"
    },
    on: {
      "action": function action($event) {
        return _vm.hideModalConfirmChangeEmail();
      }
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "Save"
    },
    on: {
      "action": function action($event) {
        return _vm.changeEmail();
      }
    }
  })], 1)])], 2), _c('Modal', {
    staticClass: "overflow-y-hidden",
    attrs: {
      "modalVisible": _vm.visibleModalConfirmChangeEmail,
      "id": "confirmChangeEmail"
    },
    on: {
      "close": function close($event) {
        return _vm.hideModalConfirmChangeEmail();
      }
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('h1', {
    staticClass: "text-2xl font-bold mb-3 mt-5 text-left"
  }, [_vm._v("Change Email")]), _c('div', {
    staticClass: "text-left"
  }, [_c('p', [_vm._v("Previous email: " + _vm._s(_vm.oldEmail))]), _c('p', [_vm._v("New email: " + _vm._s(_vm.newEmail))]), _c('br'), _c('p', [_vm._v(" Note: All activity from previous email will be transferred to new email. This action can not be undone")])]), _c('div', {
    staticClass: "flex  gap-4 justify-end my-5"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Cancel",
      "type": "tertiary"
    },
    on: {
      "action": function action($event) {
        return _vm.hideModalConfirmChangeEmail();
      }
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "Save"
    },
    on: {
      "action": function action($event) {
        return _vm.changeEmail();
      }
    }
  })], 1)])], 2), _c('Modal', {
    attrs: {
      "modalVisible": _vm.visibleResendCertificateModal,
      "id": "resendCertificate"
    },
    on: {
      "close": function close($event) {
        return _vm.toggleResendCertificateModal();
      }
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('div', {
    staticClass: "text-left"
  }, [_c('h1', {
    staticClass: "text-2xl font-bold mb-3"
  }, [_vm._v("Resend Certificate")]), _c('div', {
    staticClass: "mb-3"
  }, [_c('h6', {
    staticClass: "text-xs text-neutral-500 text-left block mb-1"
  }, [_vm._v("Current Student Name")]), _c('p', {
    staticClass: "text-sm font-medium text-neutral"
  }, [_vm._v(_vm._s((_vm$studentSelected = _vm.studentSelected) === null || _vm$studentSelected === void 0 ? void 0 : _vm$studentSelected.name))])]), _c('TextField', {
    attrs: {
      "borderEnabled": "",
      "isLabelRequire": "",
      "label": "New Student Name",
      "placeholder": "Input New Student Name"
    },
    model: {
      value: _vm.newStudentName,
      callback: function callback($$v) {
        _vm.newStudentName = $$v;
      },
      expression: "newStudentName"
    }
  }), _c('div', {
    staticClass: "grid grid-cols-2 gap-3 mt-5"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Confirm",
      "disabled": _vm.newStudentName === ''
    },
    on: {
      "action": function action($event) {
        return _vm.resendCertificate();
      }
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "Cancel",
      "type": "secondary"
    },
    on: {
      "action": function action($event) {
        return _vm.toggleResendCertificateModal();
      }
    }
  })], 1)], 1)])], 2), _vm.stillLoading ? _c('div', {
    staticClass: "loading-page"
  }, [_c('vue-simple-spinner', {
    attrs: {
      "size": "large",
      "message": "Fetching Data ..."
    }
  })], 1) : _c('div', [_c('div', {
    staticClass: "mb-6 flex justify-between items-center"
  }, [_c('h1', {
    staticClass: "text-3xl font-bold"
  }, [_vm._v("Attendance Report")]), _c('Button', {
    attrs: {
      "disabled": !_vm.selectedSchedule,
      "buttonText": "Download"
    },
    on: {
      "action": _vm.downloadReport
    }
  })], 1), _c('div', {
    staticClass: "bg-white p-4 shadow-small rounded-lg card"
  }, [_c('div', {
    staticClass: "form-group m-form__group grid grid-cols-4 justify-start gap-4 mb-6"
  }, [_vm._l(_vm.filters, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "p-0"
    }, [item.field !== 'action' && item.field !== 'kelas_id' && item.field !== 'jadwal_kelas_id' ? _c('label', {
      staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
    }, [_vm._v(_vm._s(item.label))]) : _vm._e(), item.field === 'ecommerce' ? _c('div', {}, [_c('Dropdown', {
      attrs: {
        "options": _vm.dpList,
        "optionLabel": "name",
        "default": _vm.selectedDP,
        "placeholder": "Select Ecommerce"
      },
      on: {
        "change": function change($event) {
          return _vm.searchByEcommerce();
        }
      },
      model: {
        value: _vm.selectedDP,
        callback: function callback($$v) {
          _vm.selectedDP = $$v;
        },
        expression: "selectedDP"
      }
    })], 1) : item.field == 'program_code' ? _c('div', {}, [_c('Dropdown', {
      attrs: {
        "options": _vm.programOptions,
        "optionLabel": "name",
        "default": _vm.selectedProgram,
        "placeholder": "Select Program"
      },
      on: {
        "change": function change($event) {
          return _vm.searchByProgram();
        }
      },
      model: {
        value: _vm.selectedProgram,
        callback: function callback($$v) {
          _vm.selectedProgram = $$v;
        },
        expression: "selectedProgram"
      }
    })], 1) : item.field == 'kelas_id' ? _c('div', {
      staticClass: "relative"
    }, [_c('label', {
      staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
    }, [_vm._v("Class "), !_vm.selectedProgram ? _c('span', {
      staticClass: "inline-block text-neutral-300"
    }, [_vm._v("(Program required)")]) : _vm._e()]), _c('div', {
      staticClass: "relative"
    }, [_c('TextField', {
      attrs: {
        "disabled": !_vm.selectedProgram,
        "borderEnabled": "",
        "withIcon": "",
        "placeholder": "Search Class..."
      },
      on: {
        "input": function input($event) {
          return _vm.searchClass();
        }
      },
      model: {
        value: _vm.classKeyword,
        callback: function callback($$v) {
          _vm.classKeyword = $$v;
        },
        expression: "classKeyword"
      }
    }), _c('div', {
      staticClass: "absolute left-3 top-1/2 transform -translate-y-1/2"
    }, [_c('Search')], 1)], 1), _vm.isSearching ? _c('div', {
      staticClass: "absolute left-0 py-2 max-h-40 overflow-y-auto rounded-lg w-full bg-neutral-500 bg-opacity-90 z-10"
    }, [_vm.list_kelas.length > 0 && !_vm.isFetching ? _vm._l(_vm.list_kelas, function (item_kelas) {
      return _c('div', {
        key: item_kelas.id,
        staticClass: "py-1 px-3 text-white cursor-pointer hover:bg-yellow-primary hover:text-white font-normal last:pb-0",
        on: {
          "click": function click($event) {
            return _vm.selectClass(item_kelas);
          }
        }
      }, [_vm._v(" " + _vm._s(item_kelas.nama) + " ")]);
    }) : _vm._e(), _vm.list_kelas.length === 0 && !_vm.isFetching ? [_c('p', {
      staticClass: "py-1 px-3 text-white font-normal text-center"
    }, [_vm._v("Class is not found.")])] : _vm._e()], 2) : _vm._e()]) : item.field == 'jadwal_kelas_id' ? _c('div', {
      staticClass: "relative"
    }, [_c('label', {
      staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
    }, [_vm._v("Class Schedule "), !_vm.selectedClass ? _c('span', {
      staticClass: "inline-block text-neutral-300"
    }, [_vm._v("(Class required)")]) : _vm._e()]), _c('div', {
      staticClass: "relative"
    }, [_c('TextField', {
      attrs: {
        "disabled": !_vm.selectedClass,
        "borderEnabled": "",
        "withIcon": "",
        "placeholder": "Search Class Schedule..."
      },
      on: {
        "input": function input($event) {
          return _vm.searchClassSchedule();
        }
      },
      model: {
        value: _vm.classScheduleKeyword,
        callback: function callback($$v) {
          _vm.classScheduleKeyword = $$v;
        },
        expression: "classScheduleKeyword"
      }
    }), _c('div', {
      staticClass: "absolute left-3 top-1/2 transform -translate-y-1/2"
    }, [_c('Search')], 1)], 1), _vm.isSearchingSchedule ? _c('div', {
      staticClass: "absolute left-0 py-2 max-h-40 overflow-y-auto rounded-lg w-full bg-neutral-500 bg-opacity-90 z-10"
    }, [_vm.list_jadwalkelas.length > 0 && !_vm.isFetchingSchedule ? _vm._l(_vm.list_jadwalkelas, function (item_jadwal_kelas) {
      return _c('div', {
        key: item_jadwal_kelas.id,
        staticClass: "py-1 px-3 text-white cursor-pointer hover:bg-yellow-primary hover:text-white font-normal last:pb-0",
        on: {
          "click": function click($event) {
            return _vm.selectClassSchedule(item_jadwal_kelas);
          }
        }
      }, [_vm._v(" " + _vm._s(item_jadwal_kelas.nama) + " ")]);
    }) : _vm._e(), _vm.list_jadwalkelas.length === 0 && !_vm.isFetchingSchedule ? [_c('p', {
      staticClass: "py-1 px-3 text-white font-normal text-center"
    }, [_vm._v("Class schedule is not found.")])] : _vm._e()], 2) : _vm._e()]) : item.field !== 'btn' ? _c('div', {
      staticClass: "m-input-icon m-input-icon--left"
    }, [_c('TextField', {
      attrs: {
        "type": "text",
        "enterKeyAction": _vm.onSearch,
        "borderEnabled": "",
        "placeholder": 'Search by ' + item.label
      },
      model: {
        value: _vm.options[item.field],
        callback: function callback($$v) {
          _vm.$set(_vm.options, item.field, $$v);
        },
        expression: "options[item.field]"
      }
    })], 1) : _vm._e()]);
  }), _c('div', {
    staticClass: "flex items-center pt-4"
  }, [_c('Button', {
    attrs: {
      "additionalClass": "py-3",
      "type": "tertiary",
      "buttonText": "Reset Filter"
    },
    on: {
      "action": _vm.resetFilter
    }
  })], 1)], 2), _c('vue-good-table', {
    attrs: {
      "mode": "remote",
      "pagination-options": {
        enabled: true,
        mode: 'pages',
        perPage: _vm.perPage,
        position: 'bottom',
        perPageDropdown: [5, 10, 25, 50],
        dropdownAllowAll: false,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page',
        // for 'pages' mode
        allLabel: 'All'
      },
      "totalRows": _vm.totalRecords,
      "rows": _vm.data,
      "columns": _vm.columns,
      "sort-options": {
        enabled: false
      },
      "row-style-class": _vm.rowStyleClassFn
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-per-page-change": function onPerPageChange($event) {
        return _vm.onPageChange($event, 'per-page');
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        return [props.column.field == 'btn' ? _c('div', {
          staticClass: "flex flex-col gap-2 whitespace-nowrap"
        }, [_c('div', {
          staticClass: "flex justify-between items-center"
        }, [_c('Button', {
          staticClass: "mr-2",
          attrs: {
            "type": "primary",
            "size": "icon",
            "icon": function icon() {
              return import(
              /* webpackChunkName: 'icon' */
              '@/components/Icons/Eye');
            },
            "tooltip": {
              show: true,
              position: '170%',
              text: 'Edit'
            },
            "forIcon": {
              height: '24',
              width: '24',
              color: '#FFFFFF'
            }
          },
          on: {
            "action": function action($event) {
              return _vm.editMaster(props.row.student_lms_class_id);
            }
          }
        }), _c('Button', {
          staticClass: "mr-2",
          attrs: {
            "disabled": _vm.isResendCertificateButtonDisable(props.row),
            "type": "secondary",
            "size": "icon",
            "icon": function icon() {
              return import(
              /* webpackChunkName: 'icon' */
              '@/components/Icons/DocumentText');
            },
            "forIcon": {
              height: '24',
              width: '24',
              color: "".concat(!_vm.isResendCertificateButtonDisable(props.row) ? 'yellow' : '')
            },
            "tooltip": {
              show: !_vm.isResendCertificateButtonDisable(props.row),
              position: '-10%',
              text: 'Resend Certificate'
            }
          },
          on: {
            "action": function action($event) {
              return _vm.toggleResendCertificateModal(props.row);
            }
          }
        }), _c('Button', {
          staticClass: "mr-2",
          attrs: {
            "type": "tertiary",
            "size": "icon",
            "icon": function icon() {
              return import(
              /* webpackChunkName: 'icon' */
              '@/components/Icons/Email');
            },
            "forIcon": {
              height: '24',
              width: '24',
              color: '#3b82f6'
            },
            "tooltip": {
              show: true,
              position: '10%',
              text: 'Change Email'
            }
          },
          on: {
            "action": function action($event) {
              return _vm.showModalChangeEmail(props.row.student_lms_class_id, props.row.email);
            }
          }
        }), _c('Button', {
          staticClass: "mr-2",
          attrs: {
            "type": "tertiary",
            "size": "icon",
            "icon": function icon() {
              return import(
              /* webpackChunkName: 'icon' */
              '@/components/Icons/Restart');
            },
            "forIcon": {
              height: '24',
              width: '24',
              color: '#3b82f6'
            },
            "tooltip": {
              show: true,
              position: '10%',
              text: 'Restart Progress'
            }
          },
          on: {
            "action": function action($event) {
              return _vm.showModal(props.row.student_lms_class_id);
            }
          }
        }), _c('Button', {
          attrs: {
            "type": "delete",
            "size": "icon",
            "icon": function icon() {
              return import(
              /* webpackChunkName: 'icon' */
              '@/components/Icons/Trash');
            },
            "tooltip": {
              show: true,
              position: '140%',
              text: 'Delete'
            }
          },
          on: {
            "action": function action($event) {
              return _vm.deleteMaster(props.row.student_lms_class_id);
            }
          }
        })], 1)]) : props.column.field === 'access' ? _c('div', {
          staticClass: "whitespace-nowrap"
        }, [_c('Button', {
          attrs: {
            "buttonText": "Get Access"
          },
          on: {
            "action": function action($event) {
              return _vm.getAccess(props.row.student_lms_class_id);
            }
          }
        })], 1) : props.column.field === 'core_class_name' ? _c('div', [_c('div', {
          staticClass: "nama-kelas"
        }, [_c('div', {
          staticClass: "text-yellow font-medium cursor-pointer",
          on: {
            "click": function click($event) {
              return _vm.openNewPage({
                path: '/kelas',
                query: {
                  name: props.formattedRow[props.column.field]
                }
              });
            }
          }
        }, [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])])]) : props.column.field === 'lms_class_name' ? _c('div', [_c('div', {
          staticClass: "nama-kelas"
        }, [_c('div', {
          staticClass: "text-yellow font-medium cursor-pointer",
          on: {
            "click": function click($event) {
              return _vm.openNewPage({
                path: '/jadwalkelas',
                query: {
                  name: props.formattedRow[props.column.field]
                }
              });
            }
          }
        }, [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ( " + _vm._s(_vm.formatDate(props.row.lms_class_startAt).date) + " "), props.row.lms_class_endAt ? _c('span', [_vm._v("- " + _vm._s(_vm.formatDate(props.row.lms_class_endAt).date))]) : _vm._e(), _vm._v(" ) ")])])]) : props.column.field === 'customer_name' ? _c('div', [_c('div', {
          staticStyle: {
            "white-space": "nowrap"
          }
        }, [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])]) : props.column.field === 'tugas_submit_url' || props.column.field === 'competence_certificate_url' || props.column.field === 'completion_certificate_url' || props.column.field === 'predicated_certificate_url' ? _c('div', [props.formattedRow[props.column.field] ? _c('div', {
          class: "".concat(props.formattedRow[props.column.field] === '-' ? '' : 'cursor-pointer text-yellow font-medium'),
          on: {
            "click": function click($event) {
              return _vm.openLink(props.formattedRow[props.column.field]);
            }
          }
        }, [_vm._v(" " + _vm._s(props.formattedRow[props.column.field] === '-' ? '-' : 'Link') + " ")]) : _vm._e()]) : props.column.field === 'created_date' ? _c('div', [props.formattedRow[props.column.field] ? _c('div', [_c('span', {
          staticStyle: {
            "display": "block",
            "white-space": "nowrap"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDateUtcToLocal(props.formattedRow[props.column.field]).date) + " ")]), _c('span', {
          staticStyle: {
            "display": "block",
            "font-size": "14px",
            "color": "#333"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDateUtcToLocal(props.formattedRow[props.column.field]).time) + " ")])]) : _c('div', [_vm._v("-")])]) : props.column.field === 'reported_date' || props.column.field === 'completion_date' ? _c('div', [props.formattedRow[props.column.field] ? _c('div', [_c('span', {
          staticStyle: {
            "display": "block",
            "white-space": "nowrap"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(props.formattedRow[props.column.field]).date) + " ")]), _c('span', {
          staticStyle: {
            "display": "block",
            "font-size": "14px",
            "color": "#333"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(props.formattedRow[props.column.field]).time) + " ")])]) : _c('div', [_vm._v("-")])]) : _c('span', [_vm._v(" " + _vm._s(!props.formattedRow[props.column.field] && props.formattedRow[props.column.field] !== 0 ? '-' : props.formattedRow[props.column.field]) + " ")])];
      }
    }])
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }