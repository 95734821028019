<template>
  <div>
    <Modal class="overflow-y-hidden" :modalVisible="visibleModal" @close="hideModal()" width="50%" id="restartProgress">
      <template slot="modal-content">
        <h1 class="text-2xl font-bold mb-3 mt-5">Are you sure to restart student progress?</h1>
        <p>This action can not be undone</p>
        <div class="flex justify-center items-center my-5">
          <Button class="mr-5" buttonText="Restart" type="secondary" @action="restartProgress()" />
          <Button buttonText="Cancel" type="tertiary" @action="hideModal()" />
        </div>
      </template>
    </Modal>
    <Modal class="overflow-y-hidden" :modalVisible="visibleModalChangeEmail" @close="hideModalChangeEmail()" id="changeEmail">
      <template slot="modal-content">
        <h1 class="text-2xl font-bold mb-3 mt-5 text-left">Change Email</h1>
        <div class="text-base font-semibold text-left mb-2">Current Email: {{ oldEmail }}</div>
        <br/>
        <TextField type="text" class="mb-3" label="New Email" placeholder="placeholder" v-model="newEmail" borderEnabled />
        <div class="flex  gap-4 justify-end my-5">
          <Button buttonText="Cancel" type="tertiary" @action="hideModalChangeEmail()" />
          <Button buttonText="Save"  @action="showModalConfirmChangeEmail()" />
        </div>
      </template>
    </Modal>
    <Modal class="overflow-y-hidden" :modalVisible="visibleModalConfirmChangeEmail" @close="hideModalConfirmChangeEmail()" id="confirmChangeEmail">
      <template slot="modal-content">
        <h1 class="text-2xl font-bold mb-3 mt-5 text-left">Change Email</h1>
        <div class="text-left">
        <p >Previous email: {{ oldEmail }}</p>
        <p >New email: {{ newEmail }}</p>
        <br/>
        <p> Note: All activity from previous email will be transferred to new email. This action can not be undone</p>
        </div>
        <div class="flex  gap-4 justify-end my-5">
          <Button buttonText="Cancel" type="tertiary" @action="hideModalConfirmChangeEmail()" />
          <Button buttonText="Save" @action="changeEmail()" />
        </div>
      </template>
    </Modal>
    <Modal class="overflow-y-hidden" :modalVisible="visibleModalConfirmChangeEmail" @close="hideModalConfirmChangeEmail()" id="confirmChangeEmail">
      <template slot="modal-content">
        <h1 class="text-2xl font-bold mb-3 mt-5 text-left">Change Email</h1>
        <div class="text-left">
        <p >Previous email: {{ oldEmail }}</p>
        <p >New email: {{ newEmail }}</p>
        <br/>
        <p> Note: All activity from previous email will be transferred to new email. This action can not be undone</p>
        </div>
        <div class="flex  gap-4 justify-end my-5">
          <Button buttonText="Cancel" type="tertiary" @action="hideModalConfirmChangeEmail()" />
          <Button buttonText="Save" @action="changeEmail()" />
        </div>
      </template>
    </Modal>
    <Modal :modalVisible="visibleResendCertificateModal" @close="toggleResendCertificateModal()" id="resendCertificate">
      <template slot="modal-content">
        <div class="text-left">
          <h1 class="text-2xl font-bold mb-3">Resend Certificate</h1>
          <div class="mb-3">
            <h6 class="text-xs text-neutral-500 text-left block mb-1">Current Student Name</h6>
            <p class="text-sm font-medium text-neutral">{{ studentSelected?.name }}</p>
          </div>
          <TextField borderEnabled isLabelRequire v-model="newStudentName" label="New Student Name" placeholder="Input New Student Name" />
          <div class="grid grid-cols-2 gap-3 mt-5">
            <Button buttonText="Confirm" :disabled="newStudentName === ''" @action="resendCertificate()" />
            <Button buttonText="Cancel" type="secondary" @action="toggleResendCertificateModal()" />
          </div>
        </div>
      </template>
    </Modal>
    <div v-if="stillLoading" class="loading-page">
      <vue-simple-spinner size="large" message="Fetching Data ..."></vue-simple-spinner>
    </div>
    <div v-else>
      <div class="mb-6 flex justify-between items-center">
        <h1 class="text-3xl font-bold">Attendance Report</h1>
        <Button :disabled="!selectedSchedule" buttonText="Download" @action="downloadReport" />
      </div>
      <div class="bg-white p-4 shadow-small rounded-lg card">
        <div class="form-group m-form__group grid grid-cols-4 justify-start gap-4 mb-6">
          <div class="p-0" v-for="item in filters" v-bind:key="item.id">
            <label v-if="item.field !== 'action' && item.field !== 'kelas_id' && item.field !== 'jadwal_kelas_id'" class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">{{
              item.label
            }}</label>
            <div class="" v-if="item.field === 'ecommerce'">
              <Dropdown :options="dpList" optionLabel="name" v-model="selectedDP" @change="searchByEcommerce()" :default="selectedDP" placeholder="Select Ecommerce" />
            </div>
            <div class="" v-else-if="item.field == 'program_code'">
              <Dropdown :options="programOptions" optionLabel="name" v-model="selectedProgram" @change="searchByProgram()" :default="selectedProgram" placeholder="Select Program" />
            </div>
            <div v-else-if="item.field == 'kelas_id'" class="relative">
              <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Class <span v-if="!selectedProgram" class="inline-block text-neutral-300">(Program required)</span></label>
              <div class="relative">
                <TextField :disabled="!selectedProgram" borderEnabled withIcon v-model="classKeyword" @input="searchClass()" placeholder="Search Class..." />
                <div class="absolute left-3 top-1/2 transform -translate-y-1/2">
                  <Search />
                </div>
              </div>
              <div class="absolute left-0 py-2 max-h-40 overflow-y-auto rounded-lg w-full bg-neutral-500 bg-opacity-90 z-10" v-if="isSearching">
                <template v-if="list_kelas.length > 0 && !isFetching">
                  <div
                    @click="selectClass(item_kelas)"
                    class="py-1 px-3 text-white cursor-pointer hover:bg-yellow-primary hover:text-white font-normal last:pb-0"
                    v-for="item_kelas in list_kelas"
                    :key="item_kelas.id"
                  >
                    {{ item_kelas.nama }}
                  </div>
                </template>
                <template v-if="list_kelas.length === 0 && !isFetching">
                  <p class="py-1 px-3 text-white font-normal text-center">Class is not found.</p>
                </template>
              </div>
            </div>
            <div v-else-if="item.field == 'jadwal_kelas_id'" class="relative">
              <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
                >Class Schedule <span v-if="!selectedClass" class="inline-block text-neutral-300">(Class required)</span></label
              >
              <div class="relative">
                <TextField :disabled="!selectedClass" borderEnabled withIcon v-model="classScheduleKeyword" @input="searchClassSchedule()" placeholder="Search Class Schedule..." />
                <div class="absolute left-3 top-1/2 transform -translate-y-1/2">
                  <Search />
                </div>
              </div>
              <div class="absolute left-0 py-2 max-h-40 overflow-y-auto rounded-lg w-full bg-neutral-500 bg-opacity-90 z-10" v-if="isSearchingSchedule">
                <template v-if="list_jadwalkelas.length > 0 && !isFetchingSchedule">
                  <div
                    @click="selectClassSchedule(item_jadwal_kelas)"
                    class="py-1 px-3 text-white cursor-pointer hover:bg-yellow-primary hover:text-white font-normal last:pb-0"
                    v-for="item_jadwal_kelas in list_jadwalkelas"
                    :key="item_jadwal_kelas.id"
                  >
                    {{ item_jadwal_kelas.nama }}
                  </div>
                </template>
                <template v-if="list_jadwalkelas.length === 0 && !isFetchingSchedule">
                  <p class="py-1 px-3 text-white font-normal text-center">Class schedule is not found.</p>
                </template>
              </div>
            </div>
            <div v-else-if="item.field !== 'btn'" class="m-input-icon m-input-icon--left">
              <TextField type="text" :enterKeyAction="onSearch" borderEnabled v-model="options[item.field]" :placeholder="'Search by ' + item.label" />
            </div>
          </div>
          <div class="flex items-center pt-4">
            <Button additionalClass="py-3" type="tertiary" buttonText="Reset Filter" @action="resetFilter" />
          </div>
        </div>
        <vue-good-table
          mode="remote"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: perPage,
            position: 'bottom',
            perPageDropdown: [5, 10, 25, 50],
            dropdownAllowAll: false,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page', // for 'pages' mode
            allLabel: 'All'
          }"
          :totalRows="totalRecords"
          :rows="data"
          :columns="columns"
          :sort-options="{
            enabled: false
          }"
          @on-page-change="onPageChange"
          @on-per-page-change="onPageChange($event, 'per-page')"
          :row-style-class="rowStyleClassFn"
        >
          <template slot="table-row" slot-scope="props">
            <div v-if="props.column.field == 'btn'" class="flex flex-col gap-2 whitespace-nowrap">
              <div class="flex justify-between items-center">
                <Button
                  class="mr-2"
                  type="primary"
                  size="icon"
                  :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Eye')"
                  :tooltip="{ show: true, position: '170%', text: 'Edit' }"
                  :forIcon="{ height: '24', width: '24', color: '#FFFFFF' }"
                  @action="editMaster(props.row.student_lms_class_id)"
                />
                <Button
                  :disabled="isResendCertificateButtonDisable(props.row)"
                  class="mr-2"
                  type="secondary"
                  size="icon"
                  :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/DocumentText')"
                  :forIcon="{ height: '24', width: '24', color: `${!isResendCertificateButtonDisable(props.row) ? 'yellow' : ''}` }"
                  :tooltip="{ show: !isResendCertificateButtonDisable(props.row), position: '-10%', text: 'Resend Certificate' }"
                  @action="toggleResendCertificateModal(props.row)"
                />
                <Button
                  class="mr-2"
                  type="tertiary"
                  size="icon"
                  :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Email')"
                  :forIcon="{ height: '24', width: '24', color: '#3b82f6' }"
                  :tooltip="{ show: true, position: '10%', text: 'Change Email' }"
                  @action="showModalChangeEmail(props.row.student_lms_class_id, props.row.email)"
                />
                <Button
                  class="mr-2"
                  type="tertiary"
                  size="icon"
                  :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Restart')"
                  :forIcon="{ height: '24', width: '24', color: '#3b82f6' }"
                  :tooltip="{ show: true, position: '10%', text: 'Restart Progress' }"
                  @action="showModal(props.row.student_lms_class_id)"
                />
                <Button
                  type="delete"
                  size="icon"
                  :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash')"
                  :tooltip="{ show: true, position: '140%', text: 'Delete' }"
                  @action="deleteMaster(props.row.student_lms_class_id)"
                />
              </div>
            </div>
            <div v-else-if="props.column.field === 'access'" class="whitespace-nowrap">
              <Button buttonText="Get Access" @action="getAccess(props.row.student_lms_class_id)" />
            </div>
            <div v-else-if="props.column.field === 'core_class_name'">
              <div class="nama-kelas">
                <div class="text-yellow font-medium cursor-pointer" @click="openNewPage({ path: '/kelas', query: { name: props.formattedRow[props.column.field] } })">
                  {{ props.formattedRow[props.column.field] }}
                </div>
              </div>
            </div>
            <div v-else-if="props.column.field === 'lms_class_name'">
              <div class="nama-kelas">
                <div class="text-yellow font-medium cursor-pointer" @click="openNewPage({ path: '/jadwalkelas', query: { name: props.formattedRow[props.column.field] } })">
                  {{ props.formattedRow[props.column.field] }}
                  ( {{ formatDate(props.row.lms_class_startAt).date }} <span v-if="props.row.lms_class_endAt">- {{ formatDate(props.row.lms_class_endAt).date }}</span> )
                </div>
              </div>
            </div>
            <div v-else-if="props.column.field === 'customer_name'">
              <div style="white-space: nowrap">
                {{ props.formattedRow[props.column.field] }}
              </div>
            </div>
            <div
              v-else-if="
                props.column.field === 'tugas_submit_url' ||
                props.column.field === 'competence_certificate_url' ||
                props.column.field === 'completion_certificate_url' ||
                props.column.field === 'predicated_certificate_url'
              "
            >
              <div
                v-if="props.formattedRow[props.column.field]"
                :class="`${props.formattedRow[props.column.field] === '-' ? '' : 'cursor-pointer text-yellow font-medium'}`"
                @click="openLink(props.formattedRow[props.column.field])"
              >
                {{ props.formattedRow[props.column.field] === '-' ? '-' : 'Link' }}
              </div>
            </div>
            <div v-else-if="props.column.field === 'created_date'">
              <div v-if="props.formattedRow[props.column.field]">
                <span style="display: block; white-space: nowrap">
                  {{ formatDateUtcToLocal(props.formattedRow[props.column.field]).date }}
                </span>
                <span style="display: block; font-size: 14px; color: #333">
                  {{ formatDateUtcToLocal(props.formattedRow[props.column.field]).time }}
                </span>
              </div>
              <div v-else>-</div>
            </div>
            <div v-else-if="props.column.field === 'reported_date' || props.column.field === 'completion_date'">
              <div v-if="props.formattedRow[props.column.field]">
                <span style="display: block; white-space: nowrap">
                  {{ formatDate(props.formattedRow[props.column.field]).date }}
                </span>
                <span style="display: block; font-size: 14px; color: #333">
                  {{ formatDate(props.formattedRow[props.column.field]).time }}
                </span>
              </div>
              <div v-else>-</div>
            </div>
            <span v-else>
              {{ !props.formattedRow[props.column.field] && props.formattedRow[props.column.field] !== 0 ? '-' : props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import { showVueToast } from '@/utils'
export default {
  name: 'AbsensiReport',
  components: {
    Modal: () => import(/* webpackChunkName: "Modal" */ '@/components/Modal/Modal'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Search: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Search'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown')
  },
  created: function () {
    this.$store.dispatch('kelas/GET_LIST_PROGRAM').then((response) => {
      this.programOptions = response.data.data
    })
    this.initData()
  },
  data: function () {
    return {
      selectedDP: null,
      selectedSchedule: null,
      selectedProgram: null,
      selectedClass: null,
      searchTerm: null,
      options: { lms_class_id: '', core_class_id: '', ecommerce: '', program_code: '', limit: 10, page: 0, name: '' },
      programOptions: [],
      list_kelas: [],
      perPage: 10,
      showProgress: true,
      counterProgress: 0,
      modeForm: '',
      forStudentId: '',
      oldEmail: '',
      newEmail: '',
      visibleModal: false,
      visibleModalChangeEmail: false,
      visibleModalConfirmChangeEmail: false,
      locked: false,
      unlockPassword: '',
      stillLoading: true,
      testingName: '',
      classKeyword: '',
      debounce: null,
      isFetching: false,
      isSearching: false,
      classScheduleKeyword: '',
      debounceSchedule: null,
      isFetchingSchedule: false,
      isSearchingSchedule: false,
      list_jadwalkelas: [],
      list_status_kepesertaan: [
        { id: 1, name: 'Menunggu Kelas' },
        { id: 2, name: 'Mengikuti Kelas' },
        { id: 3, name: 'Exam dan Evaluasi' },
        { id: 4, name: 'Sertifikat' },
        { id: 5, name: 'Pelaporan ke Ecommerce' }
      ],
      visibleResendCertificateModal: false,
      studentSelected: null,
      newStudentName: ''
    }
  },
  methods: {
    ...mapActions('common', ['DIGITAL_PLATFORMS', 'showLoading', 'hideLoading']),
    ...mapActions('absensireport', ['DOWNLOAD_ABSENSIREPORT', 'RESEND_CERTIFICATE']),
    openNewPage(params) {
      const routeData = this.$router.resolve(params)
      window.open(routeData.href, '_blank')
    },
    openLink(link) {
      if (link === '-') return
      window.open(link, '_blank')
    },
    downloadReport() {
      this.showLoading()
      this.options.limit = ''
      this.options.page = ''
      this.options.nama = ''
      let paramsTemp = queryString.stringify({
        ...this.options
      })
      this.DOWNLOAD_ABSENSIREPORT({
        masterType: this.$route.meta.name,
        customerId: this.clientId,
        params: paramsTemp
      }).then((response) => {
        this.hideLoading()
        const url = URL.createObjectURL(response.data)
        const a = document.createElement('a')
        a.download = 'Absensi Report.xlsx'
        a.href = url
        a.target = '_self'
        a.click()
        setTimeout(function () {
          a.remove()
          URL.revokeObjectURL(url)
        }, 100)
      })
    },
    searchClass() {
      if (this.classKeyword !== '') {
        clearTimeout(this.debounce)
        let self = this
        this.debounce = setTimeout(() => {
          if (this.classKeyword !== '') {
            self.getListKelas()
          }
        }, 300)
      } else {
        this.isSearching = false
      }
    },
    selectClass(item) {
      this.resetClassSchedule()
      this.options['core_class_id'] = item.id
      this.classKeyword = item.nama
      this.selectedClass = item.nama
      this.isSearching = false
      this.onSearch()
    },
    searchClassSchedule() {
      if (this.classScheduleKeyword !== '') {
        clearTimeout(this.debounceSchedule)
        let self = this
        this.debounceSchedule = setTimeout(() => {
          if (this.classScheduleKeyword !== '') {
            self.getListJadwalKelas()
          }
        }, 300)
      } else {
        this.isSearchingSchedule = false
      }
    },
    selectClassSchedule(item) {
      this.options['lms_class_id'] = item.id
      this.classScheduleKeyword = item.nama
      this.selectedSchedule = item.nama
      this.isSearchingSchedule = false
      this.onSearch()
    },
    resetClass() {
      this.options['core_class_id'] = this.classKeyword = this.selectedClass = null
    },
    resetClassSchedule() {
      this.options['lms_class_id'] = this.classScheduleKeyword = this.selectedSchedule = null
    },
    resetFilter() {
      this.showLoading()
      this.resetClass()
      this.resetClassSchedule()
      this.selectedDP = null
      this.selectedProgram = null
      this.options = {
        lms_class_id: '',
        core_class_id: '',
        ecommerce: '',
        program_code: '',
        limit: 10,
        page: 0,
        name: ''
      }
      this.initData()
    },
    showModal(id) {
      this.forStudentId = id
      this.visibleModal = true
    },
    hideModal() {
      this.visibleModal = false
    },
    showModalChangeEmail(id, email) {
      this.forStudentId = id
      this.oldEmail = email
      this.newEmail = ''
      this.visibleModalChangeEmail = true
    },
    hideModalChangeEmail() {
      this.visibleModalChangeEmail = false
    },
    showModalConfirmChangeEmail(){
      this.visibleModalChangeEmail = false
      this.visibleModalConfirmChangeEmail = true
    },
    hideModalConfirmChangeEmail(){
      this.visibleModalConfirmChangeEmail = false
    },
    restartProgress() {
      let paramsTemp = queryString.stringify({
        ...{
          limit: null,
          page: null,
          nama: null,
          sort: 'name',
          testingName: this.$route.meta.testingName
        },
        ...this.options
      })
      this.$store
        .dispatch('absensireport/RESTART_PROGRESS', {
          customerId: this.clientId,
          studentLmsClassId: this.forStudentId
        })
        .then((resp) => {
          if (resp.data.status == '200 OK') {
            this.$toasted.show('Restart student progress successfully!', {
              position: 'top-center',
              type: 'success',
              duration: 3000,
              theme: 'bubble',
              singleton: true
            })
          } else {
            this.$toasted.show('Restart student progress failed, please try again later!', {
              position: 'top-center',
              type: 'error',
              duration: 3000,
              theme: 'bubble',
              singleton: true
            })
          }
          this.hideModal()
          this.$store.dispatch('absensireport/GET_LIST_ABSENSIREPORT', {
            masterType: this.$route.meta.name,
            customerId: this.clientId,
            params: paramsTemp
          })
        })
        .catch(() => {
          this.$toasted.show('Restart student progress failed, please try again later!', {
            position: 'top-center',
            type: 'error',
            duration: 3000,
            theme: 'bubble',
            singleton: true
          })
          this.hideModal()
          this.$store.dispatch('absensireport/GET_LIST_ABSENSIREPORT', {
            masterType: this.$route.meta.name,
            customerId: this.clientId,
            params: paramsTemp
          })
        })
    },
    changeEmail() {
      let paramsTemp = queryString.stringify({
        ...{
          limit: null,
          page: null,
          nama: null,
          sort: 'name',
          testingName: this.$route.meta.testingName
        },
        ...this.options
      })
      this.$store
        .dispatch('absensireport/CHANGE_EMAIL', {
          customerId: this.clientId,
          studentLmsClassId: this.forStudentId,
          payload: { newEmail: this.newEmail }
        })
        .then((resp) => {
          if (resp.data !=null && resp.data.code == 200) {
            this.$toasted.show('Change email successfully!', {
              position: 'top-center',
              type: 'success',
              duration: 3000,
              theme: 'bubble',
              singleton: true
            })
          } else {
            this.$toasted.show(resp.errors.error, {
              position: 'top-center',
              type: 'error',
              duration: 3000,
              theme: 'bubble',
              singleton: true
            })
          }
          this.hideModalConfirmChangeEmail()
          this.$store.dispatch('absensireport/GET_LIST_ABSENSIREPORT', {
            masterType: this.$route.meta.name,
            customerId: this.clientId,
            params: paramsTemp
          })
        })
        .catch((err) => {
          this.$toasted.show('Change email failed', {
            position: 'top-center',
            type: 'error',
            duration: 3000,
            theme: 'bubble',
            singleton: true
          })
          this.hideModalConfirmChangeEmail()
          this.$store.dispatch('absensireport/GET_LIST_ABSENSIREPORT', {
            masterType: this.$route.meta.name,
            customerId: this.clientId,
            params: paramsTemp
          })
        })
    },
    formatDateUtcToLocal(date) {
      if (date) {
        return {
          date: moment(`${date}+0000`).local().format('DD MMM YYYY'),
          time: moment(`${date}+0000`).local().format('HH:mm:ss')
        }
      } else {
        return '-'
      }
    },
    formatDate(date) {
      if (date) {
        return {
          date: moment(date).format('DD MMM YYYY'),
          time: moment(date).format('HH:mm:ss')
        }
      } else {
        return '-'
      }
    },
    getAccess(id) {
      this.$store
        .dispatch('absensireport/GET_STUDENT_ACCESS', {
          studentId: id,
          customerId: this.clientId,
          payload: {}
        })
        .then(() => {
          showVueToast('Access is granted! Please check your email to access the student dashboard', 'success', 2000)
          this.counterProgress = 100
          this.showProgress = false
          this.stillLoading = false
        })
    },
    initData() {
      this.modeForm = this.$route.meta.mode
      this.counterProgress = 0
      let paramsTemp = queryString.stringify({
        ...{
          name: null
        },
        ...this.options
      })
      this.$store
        .dispatch('absensireport/GET_LIST_ABSENSIREPORT', {
          masterType: this.$route.meta.name,
          customerId: this.clientId,
          params: paramsTemp
        })
        .then(() => {
          this.counterProgress = 100
          this.showProgress = false
          this.stillLoading = false
          this.hideLoading()
        })
      this.getDP()
    },
    getDP() {
      this.DIGITAL_PLATFORMS().then((res) => {
        const result = res.data.data
        const all = { code: '', id: 0, name: 'All Ecommerce' }
        this.dpList = [all].concat(result)
      })
    },
    getListJadwalKelas() {
      this.isFetchingSchedule = true
      this.modeForm = this.$route.meta.mode
      this.counterProgress = 0
      let paramsTemp = queryString.stringify({
        ...{
          limit: 50,
          // program_code: this.selectedProgram.code,
          core_class_id: this.options['core_class_id'],
          name: this.classScheduleKeyword
        }
      })
      this.$store
        .dispatch('jadwalkelas/GET_LIST_JADWALKELAS', {
          masterType: this.$route.meta.name,
          customerId: this.clientId,
          params: paramsTemp
        })
        .then(() => {
          this.list_jadwalkelas = this.$store.getters['jadwalkelas/list_jadwalkelas'] ? this.$store.getters['jadwalkelas/list_jadwalkelas'] : []
          this.isSearchingSchedule = true
          this.isFetchingSchedule = false
        })
        .catch(function () {})
    },
    searchByEcommerce() {
      this.options['ecommerce'] = this.selectedDP.code
      this.onSearch()
    },
    searchByProgram() {
      this.resetClass()
      this.resetClassSchedule()
      this.options['program_code'] = this.selectedProgram.code
      this.onSearch()
    },
    getListKelas() {
      this.isFetching = true
      this.modeForm = this.$route.meta.mode
      this.counterProgress = 0
      let paramsTemp = queryString.stringify({
        ...{
          limit: 50,
          program_code: this.selectedProgram.code,
          name: this.classKeyword
        }
      })
      this.$store
        .dispatch('kelas/GET_LIST_KELAS', {
          masterType: this.$route.meta.name,
          customerId: this.clientId,
          params: paramsTemp
        })
        .then(() => {
          this.list_kelas = this.$store.getters['kelas/list_kelas'] ? this.$store.getters['kelas/list_kelas'] : []
          this.isSearching = true
          this.isFetching = false
        })
        .catch(function () {})
    },

    unlock() {
      if (this.unlockPassword == this.$store.getters['customer/customer'].password) {
        this.locked = false
      } else {
        alert('Password invalid')
      }
    },
    preview(masterId) {
      this.$router.push('/absensireport/' + masterId)
    },
    onPageChange(params) {
      this.options.limit = params.currentPerPage
      this.options.page = params.currentPage - 1
      this.showLoading()
      let paramsTemp = queryString.stringify({
        ...this.options
      })
      this.$store
        .dispatch('absensireport/GET_LIST_ABSENSIREPORT', {
          masterType: this.$route.meta.name,
          customerId: this.clientId,
          params: paramsTemp,
          testingName: this.$route.meta.testingName
        })
        .then(() => {
          this.hideLoading()
        })
    },
    onSearch() {
      this.showLoading()
      this.options.page = 0
      let paramsTemp = queryString.stringify({
        ...this.options
      })
      this.$store
        .dispatch('absensireport/GET_LIST_ABSENSIREPORT', {
          masterType: this.$route.meta.name,
          customerId: this.clientId,
          params: paramsTemp,
          testingName: this.$route.meta.testingName
        })
        .then(() => {
          this.hideLoading()
        })
        .catch(() => {
          this.hideLoading()
          showVueToast('Your request is failed', 'error', 2000)
        })
    },
    editMaster(studentLmsClassId) {
      this.$router.push('/absensi-report/' + studentLmsClassId)
    },
    correctKodeVoucher(masterId) {
      let paramsTemp = queryString.stringify({
        ...{
          limit: null,
          page: null,
          nama: null,
          sort: 'name',
          testingName: this.$route.meta.testingName
        },
        ...this.options
      })

      this.$dialog
        .confirm('Are you sure you want to correct this?', {
          okText: 'Yes',
          cancelText: 'No',
          loader: true,
          animation: 'bounce'
        })
        .then((dialog) => {
          dialog.close()
          this.$store
            .dispatch('absensireport/CORRECT_KODEVOUCHER_ABSENSIREPORT', {
              masterType: this.$route.meta.name,
              masterId: masterId,
              testingName: this.$route.meta.testingName
            })
            .then((resp) => {
              if (resp.data.status == '200 OK') {
                // this.$swal(resp.data.message)
                this.$store.dispatch('absensireport/GET_LIST_ABSENSIREPORT', {
                  masterType: this.$route.meta.name,
                  customerId: this.clientId,
                  params: paramsTemp
                })
              }
            })
        })
        .catch(function () {})
    },
    deleteMaster(masterId) {
      let paramsTemp = queryString.stringify({
        ...{
          limit: null,
          page: null,
          nama: null,
          sort: 'name',
          testingName: this.$route.meta.testingName
        },
        ...this.options
      })

      this.$dialog
        .confirm('Are you sure you want to delete this?', {
          okText: 'Yes',
          cancelText: 'No',
          loader: true,
          animation: 'bounce'
        })
        .then((dialog) => {
          dialog.close()
          this.$store
            .dispatch('absensireport/DELETE_ABSENSIREPORT', {
              masterType: this.$route.meta.name,
              customerId: this.clientId,
              masterId: masterId,
              testingName: this.$route.meta.testingName
            })
            .then((resp) => {
              if (resp.data.status == '200 OK') {
                // this.$swal(resp.data.message)
                this.$store.dispatch('absensireport/GET_LIST_ABSENSIREPORT', {
                  masterType: this.$route.meta.name,
                  customerId: this.clientId,
                  params: paramsTemp
                })
              }
            })
        })
        .catch(function () {})
    },
    defineCertificateType(student) {
      if (student.predicated_certificate_url !== '-') return 'COMPLETION_PREDIKAT'
      if (student.completion_certificate_url !== '-') return 'COMPLETION'
      return 'COMPETENCE'
    },
    isResendCertificateButtonDisable(student) {
      if (student.program_code !== 'Prakerja') return true
      if (student.predicated_certificate_url === '-' && student.completion_certificate_url === '-' && student.competence_certificate_url === '-') return true
      return false
    },
    toggleResendCertificateModal(studentSelected) {
      if (studentSelected) {
        this.newStudentName = ''
        this.studentSelected = studentSelected
      }
      this.visibleResendCertificateModal = !this.visibleResendCertificateModal
    },
    resendCertificate() {
      this.showLoading()
      const certificateType = this.defineCertificateType(this.studentSelected)
      const payload = {
        sendEmail: false,
        certificateType,
        nameOnCertificate: this.newStudentName,
        lmsClassWorkflow: 'PRAKERJA_SCHEMA_NORMAL'
      }
      this.RESEND_CERTIFICATE({
        studentLmsClassId: this.studentSelected.student_lms_class_id,
        customerId: this.clientId,
        payload
      })
        .then((res) => {
          if (res?.status === 200) {
            showVueToast('Resend certificate successfully!', 'success', 2000)
            this.toggleResendCertificateModal()
            this.initData()
            this.hideLoading()
            return
          }
          this.hideLoading()
          showVueToast('Your request is failed!', 'error', 2000)
        })
        .catch(() => {
          this.hideLoading()
          showVueToast('Your request is failed!', 'error', 2000)
        })
    },
    sendCertificate(masterId) {
      let paramsTemp = queryString.stringify({
        ...{
          limit: null,
          page: null,
          nama: null,
          sort: 'name',
          testingName: this.$route.meta.testingName
        },
        ...this.options
      })

      this.$dialog
        .confirm('Are you sure you want to resend?', {
          okText: 'Yes',
          cancelText: 'No',
          loader: true,
          animation: 'bounce'
        })
        .then((dialog) => {
          dialog.close()
          this.$store
            .dispatch('absensireport/SEND_CERTIFICATE', {
              masterType: this.$route.meta.name,
              masterId: masterId,
              testingName: this.$route.meta.testingName
            })
            .then((resp) => {
              if (resp.data.status == '200 OK') {
                // this.$swal(resp.data.message)
                this.$store.dispatch('absensireport/GET_LIST_ABSENSIREPORT', {
                  masterType: this.$route.meta.name,
                  customerId: this.clientId,
                  params: paramsTemp
                })
              }
            })
        })
        .catch(function () {})
    },
    addData() {
      this.$router.push('/' + this.$route.meta.name + '-Add')
    },
    openDisplay() {
      this.$router.push('/display/' + this.$store.getters['customer/customer'].token_trx)
    },
    resetDisplay() {
      this.$store.dispatch('customer/RESET_TOKEN_TRX', {}).then((resp) => {
        if (resp.data.status == '200 OK') {
          // this.$swal(resp.data.status)
        }
      })
    },
    rowStyleClassFn(row) {
      let result = 'default'
      if (row.removed === true) {
        result = 'red'
      }
      return result
    }
  },
  computed: {
    ...mapGetters('common', ['digitalPlatforms']),
    data: function () {
      return this.$store.getters['absensireport/list_absensireport'] ? this.$store.getters['absensireport/list_absensireport'] : []
    },
    totalRecords: function () {
      return this.$store.getters['absensireport/paginate'].total ? this.$store.getters['absensireport/paginate'].total : 0
    },
    clientId() {
      return localStorage.getItem('client')
    },
    columns: function () {
      return [
        { field: 'name', label: 'Student Name' },
        { field: 'voucher_code', label: 'Voucher Code' },
        { field: 'core_class_code', label: 'Class Code' },
        { field: 'core_class_name', label: 'Class Name' },
        { field: 'lms_class_name', label: 'Schedule Name' },
        { field: 'redeem_code_pmo', label: 'Redemption Code' },
        { field: 'invoice_code_pmo', label: 'Invoice Code' },
        { field: 'hp', label: 'Phone Number' },
        { field: 'email', label: 'Email' },
        { field: 'access', label: 'Admin Access' },
        { field: 'ecommerce', label: 'Digital Platform' },
        { field: 'created_date', label: 'Student Registered Time' },
        { field: 'completion_date', label: 'Student Completion Time' },
        { field: 'reported_date', label: 'Report Digital Platform Time' },
        { field: 'batch', label: 'Batch' },

        { field: 'pretest_value', label: 'Pre-Test Score' },
        { field: 'quiz_value', label: 'Quiz Score' },
        { field: 'first_exam_value', label: 'Post-Test Score' },
        { field: 'tugas_submit_url', label: 'Task Url' },
        { field: 'tugas_submit_score', label: 'Task Score' },
        { field: 'completion_certificate_url', label: 'Completion Certificate' },
        { field: 'competence_certificate_url', label: 'Competence Certificate' },
        { field: 'predicated_certificate_url', label: 'Predicated Certificate' },
        { field: 'customer_name', label: 'Customer' },
        { field: 'btn', label: 'Action', tdClass: 'custom-action', thClass: 'custom-action-head' }
      ]
    },
    filters: function () {
      return [
        { field: 'voucher_code', label: 'Voucher Code' },
        { field: 'name', label: 'Student Name' },
        { field: 'email', label: 'Email' },
        { field: 'ecommerce', label: 'Digital Platform' },
        { field: 'program_code', label: 'Program' },
        { field: 'kelas_id', label: 'Class ID' },
        { field: 'jadwal_kelas_id', label: 'Class Schedule ID' }
        // { field: 'action', label: '' }
      ]
    },
    level_id: function () {
      return this.$store.getters['customer/customer'] ? this.$store.getters['customer/customer'].level_id : 0
    }
  },

  watch: {
    $route(to, from) {
      this.showProgress = true
      this.initData()
    }
  }
}
</script>
<style scoped lang="scss">
.red {
  background: linear-gradient(#fffdd5, #fffdd5);
}

.green {
  background: linear-gradient(#f4f5f8, #57c137);
}

.default {
  background: linear-gradient(#f4f5f8, #f1f3f6);
}

.nama-kelas {
  min-width: 300px;
}

.card {
  &::v-deep .vgt-table thead th {
    white-space: nowrap;
  }
}
</style>
